@font-face {
  font-family: "Restaurant Menu Book";

  font-style: normal;

  font-weight: normal;

  src: local("Restaurant Menu Book"), url(fonts/Restaurant.be8e82ba.woff) format("woff");
}

@font-face {
  font-family: "Brandon Text";

  font-style: normal;

  font-weight: normal;

  src: local("Brandon Text"), url(fonts/Restaurant.be8e82ba.woff) format("woff");
}

@font-face {
  font-weight: 700;
  font-style:  normal;
  font-family: 'Circular-Loom';

  src: url('https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Bold-83b8ceaf77f49c7cffa44107561909e4.woff2') format('woff2');
}

iframe{
  display:  none !important;
}